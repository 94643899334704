<script>
export default {
  data () {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  created () {
    window.addEventListener('resize', this.resizeHandler)
  },
  mounted () {
    this.getWindowSize()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler () {
      this.getWindowSize()
    },
    getWindowSize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}

</script>
<template>
  <div>
    <router-view class="content" :class="{'mobile' : window.width < 769}" :window="window"></router-view>
  </div>
</template>
