import { createRouter, createWebHistory } from 'vue-router'
import gaok from '../views/gaok.vue'
import gaokCreation from '../views/gaok-creation.vue'

const routes = [
  {
    path: '/',
    name: 'gaok',
    component: gaok
  },
  {
    path: '/creation',
    name: 'gaokCreation',
    component: gaokCreation
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
