<script>
export default {
  name: 'translateStart',
  data() {
    return {
      progressShow: false,
      progress: 0,
      uid: 11,
      droppedTransFile: null,
      droppedGlossaryFile: null,
      isDragging: false,
      settingData: {
        projectCode: '',
        transFileName: '',
        transFileCount: '',
        transFileUnit: '',
        sourceLanguage: 'default',
        targetLanguage: 'default',
        selectedField: 'general',
        translateEngine: 'gpt35',
        translationType: 'True',
        glossaryAvailability: 'N'
      },
      isLoading: false,
      isLogin: false,
      userName: '',
      userPW: '',
    }
  },
  mounted() {
    const loginStatus = localStorage.getItem('isLogin');
    if (loginStatus === 'true') {
      this.isLogin = true;
    }
  },
  methods: {
    verifyLogin() {
      if (this.userName === '') {
        alert("아이디를 입력해주세요.");
        return;
      }

      if (this.userPW === '') {
        alert("비밀번호를 입력해주세요.");
        return;
      }

      if (this.userName === 'test' && this.userPW === 'test@1234') {
        this.isLogin = !this.isLogin;
        localStorage.setItem('isLogin', 'true');
        return;
      }

      alert("계정 정보가 올바르지 않습니다.");
    },
    logout() {
      const confirmLogout = confirm("로그아웃 하시겠습니까?");

      if (confirmLogout) {
        this.logout = false;
        localStorage.removeItem('isLogin');
        location.reload();
      }
    },
    handleFileChange(event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleGlossaryFileChange(event) {
      const files = event.target.files
      this.handleGlossaryFiles(files)
    },
    handleDragOver(event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave() {
      this.isDragging = false
    },
    handleDrop(event) {
      event.preventDefault()
      this.isDragging = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles(files) {
      const maxSize = 50 * 1024 * 1024 // 500MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert('파일 크기가 제한을 초과했습니다. (50MB)')
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedTransFile = file
        console.log(this.droppedTransFile)
        // console.log(files)
        this.postDataToServer()
      }
    },
    handleGlossaryFiles(files) {
      const maxSize = 10 * 1000 * 1000 // 10MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert('파일 크기가 제한을 초과했습니다. (100MB)')
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedGlossaryFile = file
        // console.log(files)
      }
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    openGlossaryFileInput() {
      this.$refs.glossaryFileInput.click()
    },
    formatFileSize(size) {
      if (size === 0) return '0 Bytes'
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log2(size) / 10)
      return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
    },
    resetFile() {
      this.droppedTransFile = null
    },
    async postDataToServer() {
      this.progressShow = true;
      const formData = new FormData();
      formData.append('file', this.droppedTransFile);
      formData.append('uid', this.uid);
      try {
        const response = await this.$axios.post('https://transcreation.lexcodeapi.com/tc/upload_project_file/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            if (percentage < 100) {
              this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            } else {
              this.progress = 99;
            }
          },
        });
        if (response.data.result == true) {
          this.progress = 100
          this.progressShow = false
          // 서버에서 응답 데이터가 있는 경우
          if (response.data.src_lang == 'Korean') {
            this.settingData.targetLanguage = 'English'
          } else {
            this.settingData.targetLanguage = 'Korean'
          }
          if (response.data.count_unit == 'char') {
            this.settingData.transFileUnit = '자'
          } else {
            this.settingData.transFileUnit = '단어'
          }
          this.settingData.projectCode = response.data.project_code
          this.settingData.sourceLanguage = response.data.src_lang
          this.settingData.transFileCount = response.data.count_num.toLocaleString();
          console.log(response.data);
        } else {
          alert('서버에러');
        }
      } catch (error) {
        alert('서버에러2');
        console.error(error);
      } finally {
        this.settingData.transFileName = this.droppedTransFile.name
      }
    },
  }
}
</script>


<template>
  <div class="tc main">
    <div class="overlay" v-if="progressShow"></div>
    <div class="process_wrap">
      <router-link to="/">
        <img src="../assets/img/tc_logo.png" alt="Logo" />
      </router-link>
      <div class="step active" v-if="isLogin">
        <p class="num">1</p>
        <p class="process">설정</p>
      </div>
      <div class="step" style="cursor:default" v-if="isLogin">
        <p class="num">2</p>
        <p class="process">Creation</p>
      </div>
      <button class="logout" @click="logout" v-if="isLogin">로그아웃</button>
    </div>
    <div class="container" v-if="isLogin">
      <h1 class="title">생성형 AI 솔루션이 필요한 파일을 업로드 해주세요.</h1>
      <fieldset class="file">
        <div class="drop-area" :class="{ 'drag-over': isDragging, 'isFile': droppedTransFile != null }"
          @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
          <template v-if="droppedTransFile">
            <div class="file_info">
              <img src="../assets/img/file_icon.png" />
              <a :href="droppedTransFile.url" target="_blank">{{ droppedTransFile.name }}</a>
              <p class="trans_unit_count">{{ settingData.transFileCount }}{{ settingData.transFileUnit }}</p>
            </div>
            <div class="file_info">
              <p class="file_size">{{ formatFileSize(droppedTransFile.size) }}</p>
              <img v-if="droppedTransFile" @click="resetFile" class="reset-button" src="../assets/img/close.png" />
            </div>
          </template>
          <template v-else>
            <img src="../assets/img/upload-cloud.png" />
            <p class="dragdrop">drag & drop here</p>
            <p class="available">docx, xlsx, pptx 형식만 지원 가능합니다.<br />최대 파일 첨부 크기는 50MB입니다.</p>
            <p @click="openFileInput" class="plus">파일 직접선택</p>
          </template>
        </div>
        <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
      </fieldset>
      <div class="progress_box" v-if="progressShow">
        <div class="tc_file_upload_loading_container">
          <img class="tc_file_upload_loading_contents_img" src="../assets/img/file_upload.png" alt="tc_upload_pop_icon" />
          <div class="tc_file_upload_loading_contents">
            <p class="tc_file_upload_loading_content">파일을 첨부하고 있어요</p>
            <div class="tc_file_upload_loading_bar_wrap">
              <progress v-if="progressShow" :value="progress" max="100"
                class="tc_file_upload_loading_bar_progress"></progress>
              <p class="tc_file_upload_loading_bar_percentage">{{ progress }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="after-upload">
        <h2>설정</h2>
        <div class="row first" :class="{ disable: settingData.transFileName == '' }">
          <div>
            <fieldset>
              <label>출발어 / SRC language</label>
              <select v-model="settingData.sourceLanguage">
                <option value="default">자동감지</option>
                <option value="English">영어(미국)</option>
                <option value="Korean">한국어</option>
                <option value="Japanese">일본어</option>
                <option value="Chinese(Simplified)">중국어 간체</option>
                <option value="Spanish">스페인어</option>
              </select>
            </fieldset>
          </div>
          <div>
            <fieldset>
              <label>도착어 / Target language</label>
              <select v-model="settingData.targetLanguage">
                <option value="default">선택</option>
                <option value="English">영어(미국)</option>
                <option value="Korean">한국어</option>
                <option value="Japanese">일본어</option>
                <option value="Chinese(Simplified)">중국어 간체</option>
                <option value="Spanish">스페인어</option>
              </select>
            </fieldset>
          </div>
          <div>
            <fieldset>
              <label>분야 / Field</label>
              <select v-model="settingData.selectedField">
                <option value="general">일반</option>
                <option value="humanities">인문</option>
                <option value="tourism">관광</option>
                <option value="business">경영</option>
                <option value="information-technology">IT</option>
                <option value="law">법률</option>
                <option value="finance">금융</option>
                <option value="science">과학</option>
                <option value="mechanical-engineering">기계기술</option>
                <option value="environment">환경</option>
                <option value="energy">에너지</option>
                <option value="none">해당없음</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div>
          <template v-if="isLoading == false">
            <router-link :to="`/creation?project_code=${settingData.projectCode}&trg_lang=${settingData.targetLanguage}`"
              class="confirm" :class="{ disable: settingData.transFileName == '' }">결과보기</router-link>
          </template>
          <template v-else>
            <p class="confirm loading">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </p>
          </template>
        </div>
      </div>
    </div>

    <div id="login_container" v-if="!isLogin">
      <img src="../assets//img/lexcode_logo.png" />
      <input type="text" v-model="userName" placeholder="아이디를 입력해주세요" @keydown.enter="verifyLogin" />
      <input type="password" v-model="userPW" placeholder="비밀번호를 입력해주세요" @keydown.enter="verifyLogin" />
      <button @click="verifyLogin">로그인</button>
    </div>
  </div>
</template>

