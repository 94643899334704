<script>
// import { ref } from 'vue';

export default {
  name: 'creationView',
  data () {
    return {
      Uid: 11,
      projectCode: '',
      expertBtn: false,
      creationArr: [
        {
          name: 'letter',
          label: '서신'
        },
        {
          name: 'press_release',
          label: '보도자료'
        },
        {
          name: 'product_manual',
          label: '매뉴얼'
        },
        {
          name: 'marketing_materials',
          label: '홍보자료'
        },
        {
          name: 'speeches_presentations',
          label: '연설문'
        },
        {
          name: 'ppt_presentation',
          label: '발표자료'
        },
        {
          name: 'legal_documents',
          label: '법률문서'
        },
        {
          name: 'educational_material',
          label: '교육자료'
        },
        {
          name: 'reports',
          label: '보고서'
        },
        {
          name: 'blog_post',
          label: '블로그'
        },
        {
          name: 'social_media_post',
          label: 'SNS'
        }
      ],
      creationStyle: '',
      creationStyleResult: [],
      creationStyleTransResult: [],
      autoTrans: '',
      creationDigestResult: [],
      creationKeywordResult: [],
      timestamp: new Date().getTime(),
      targetLanguage: '',
      creationTransloading: false,
      autoTransloading: false,
      keywordExportloading: false,
      creationing: false,
      page: 1,
      isLogin: true,
    }
  },
  methods: {
    logout() {
      const confirmLogout = confirm("로그아웃 하시겠습니까?");
      
      if(confirmLogout) {
        this.logout = false;
        localStorage.removeItem('isLogin');
        location.reload();
      }
    },
    exportFile(item) {
      return "https://transcreation.lexcodeapi.com/tc/download/" + this.Uid + "/" + this.projectCode + "/" + item
    },
    async creation_style_datas(style) {
      this.page = 1
      if (this.creationing) {
      alert("크리에이션 중입니다.")
      return;
    }
      this.creationStyle = style;
      this.creationStyleResult = [];
      this.creationStyleTransResult = [];
      this.creationing = true
      const myData = {
        param1: this.projectCode, // PJ CODE
        param2: this.Uid, // UID
        param3: this.timestamp, // TIMESTAMP
        param4: this.creationStyle, // STYLE
        param5: this.targetLanguage, // TRG LANG
      };
      console.log(myData)
      const websocket = new WebSocket(`wss://transcreation.lexcodeapi.com/tc/ws_style/${myData.param1}<!@!>${myData.param2}<!@!>${myData.param3}<!@!>${myData.param4}<!@!>${myData.param5}<!@!>file`)
      websocket.onclose

        websocket.onopen = (event) => {
          console.log("Connected to WebSocket", event)
          this.creationing = true
          // 연결 성공 시 처리할 로직
        }

        websocket.onmessage = (event) => {
          console.log("Message from server ", event.data)
          if(myData.param5 !== 'Korean'){
            this.creationTransloading = true;
          }
          const totalMatch = event.data.match(/<!@!>total:(\d+)<!@!>/);
          if (totalMatch) {
              const total = parseInt(totalMatch[1]);
              if (total > 0) {
                console.log(this.page)
                console.log(total)
                console.log("=================================================")
                if(total >= this.page){
                  websocket.send(this.page);
                }
                this.page++;
              }
          } else {
            this.creationStyleResult += event.data
          }
        }

        websocket.onerror = (error) => {
          console.error("WebSocket Error", error)
          // 에러 처리 로직
        }

      websocket.onclose = (event) => {
          this.creationing = false
          console.log("WebSocket connection closed", event)
          if (myData.param5 != 'Korean'){
            this.generate_style_datas_by_lang()
          } else {
            alert("스타일 윤문이 완료되었습니다.")
        }
          // 연결이 닫혔을 때 처리할 로직
        }
      },
    async generate_style_datas_by_lang() { // 크리에이션 번역 가능 여부 return (T/F)
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/generate_style_datas_by_lang",
          {
            style: this.creationStyle,
            lang: 'Korean',
          },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.read_style_datas()
        } else {
          console.log("generate_style_datas_by_lang error")
        }
      } catch (error) {
        console.error(error)
      }
    },
    async read_style_datas() { // 크리에이션 번역문 API
      try {
        const response = await this.$axios.get(`https://transcreation.lexcodeapi.com/tc/read_style_datas?style=${this.creationStyle}`,
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.creationTransloading = false;
          alert("스타일 윤문이 완료되었습니다.")
          this.creationStyleTransResult = response.data.style_datas.translated
        }
      } catch (error) {
        console.error(error)
      }
    },
    async autoTranslate() { // 자동번역
      this.autoTransloading = true;
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/create_temp_mt_data", {
            tgt_lang: this.targetLanguage,
          },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.autoTransloading = false;
          this.autoTrans = response.data.temp_mt_data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async create_digest_with_keyword() { // 키워드와 핵심요약 1
      this.keywordExportloading = true
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/create_digest_data_by_file", {
            target: "digest_trans",
            lang: "Korean"
          },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.creationDigestResult = response.data.digest_data
          this.create_keyword_data_by_file()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async create_keyword_data_by_file(){
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/create_keyword_data_by_file", {
            lang: this.targetLanguage
          },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.keywordExportloading = false
          this.creationKeywordResult = response.data.keyword_data
        }
      } catch (error) {
        console.error(error)
      }
    },
    getKoreanStyleText(style) {
      switch (style) {
        case 'press_release':
          return '보도자료';
        case 'product_manual':
          return '매뉴얼';
        case 'marketing_materials':
          return '홍보자료';
        case 'speeches_presentations':
          return '연설/발표문';
        case 'ppt_presentation':
          return '발표자료';
        case 'legal_documents':
          return '법률문서';
        case 'educational_material':
          return '교육자료';
        case 'reports':
          return '보고서';
        case 'blog_post':
          return '블로그 포스트';
        case 'social_media_post':
          return 'SNS 포스트';
        default:
          return '';
      }
    },
    getTargetLangText(trglang) {
      switch (trglang) {
        case 'Korean':
          return '한국어';
        case 'English':
          return '영어';
        case 'Japanese':
          return '일본어';
        case 'Chinese(Simplified)':
          return '중국어 간체';
        case 'Spanish':
          return '스페인어';
        default:
          return '';
      }
    },
  },
  mounted() {
    // this.BASE_SERVER_URL = this.$BASE_SERVER_URL;
    // this.socket = this.$socket;
    this.projectCode = this.$route.query.project_code
    this.targetLanguage = this.$route.query.trg_lang
    
    const loginStatus = localStorage.getItem('isLogin');
    if (loginStatus === 'true') {
      this.isLogin = true;
    }
  }
}
</script>

<template>
  <div class="creation">
    <div class="process_wrap">
      <router-link to="/">
        <img src="../assets/img/tc_logo.png" alt="Logo" />
      </router-link>
      <div class="step">
        <p class="num">1</p>
        <router-link to="/" class="process">설정</router-link>
      </div>
      <div class="step active">
        <p class="num">2</p>
        <router-link :to="`/creation?project_code=${projectCode}&trg_lang=${targetLanguage}`" class="process">Creation</router-link>
      </div>
      <button class="logout" @click="logout" v-if="isLogin">로그아웃</button>
    </div>
    <div class="creaion_wrap">
      <div class="creation_list_wrap">
        <div class="creation_list">
          <div class="creation_list_title_wrap">
            <p class="creation_list_title"><span>AI</span> 스타일 윤문</p>
            <p class="description">현재 문서를 어떤 스타일로 바꾸시겠어요?</p>
          </div>
          <div class="creation_list_cont_wrap">
            <div v-for="item, index in creationArr" :key="index" class="creation_list_label" :class="{ active: creationStyle === item.name }" @click="creation_style_datas(item.name)">
              <img :src="require(`../assets/img/${item.name}.png`)"/>
              <p>{{ item.label }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="creation_result_wrap">
        <div class="creation_result style_wrap" ref="styleSection">
          <h3>업로드 하신 문서를 원하는 스타일로 윤문해볼 수 있어요.</h3>
          <h5>스타일 윤문 : {{ creationStyle == '' ? "왼쪽 메뉴에서 윤문하실 스타일을 선택해주세요" : getKoreanStyleText(creationStyle) }}</h5>
          <p class="creation_list_label">{{ getTargetLangText(targetLanguage) }}로 윤문 <img src="../assets/img/loading.png" :class="{rotate : creationing}"/></p>
          <p class="creation_list_label" v-if="targetLanguage != 'Korean'">한국어로 윤문</p>
          <textarea class="style origin" ref="scrollContainer" v-model="creationStyleResult" readonly :class="{ korean : targetLanguage == 'Korean', [targetLanguage.substring(0,7)]: true }">
            </textarea>
          <div class="style trans" v-if="targetLanguage != 'Korean'" :class="{ 'text_loading' : creationTransloading  }">
            <template v-if="creationTransloading">
              <div class="loading transloading">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </template>
            <p class="" v-for="(item,index) in creationStyleTransResult" :key="index">
              {{ item }}
              <template v-if="item.trim() == ''">
                <br/>
              </template>
            </p>
          </div>
        </div>
        <div class="creation_result digest_wrap" ref="digestSection">
          <h3>업로드 하신 문서에 대한 번역, 핵심 요약과 키워드에요.</h3>
          <template v-if="targetLanguage !== 'Korean'">
            <h5>자동번역</h5>
            <template v-if="autoTrans !== ''">
              <div class="digest origin mt">
                {{ autoTrans }}
              </div>
            </template>
            <template v-else>
              <template v-if="autoTransloading == false">
                <p class="btn" @click="autoTranslate()">업로드한 문서의 자동번역 불러오기</p>
              </template>
              <template v-else>
                <p class="confirm loading">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                </p>
              </template>
            </template>
          </template>
          <h5>키워드와 핵심요약</h5>
          <template v-if="creationKeywordResult.length > 0 && creationDigestResult.length > 0">
            <p class="creation_list_label">키워드</p>
            <div class="keyword">
              <p v-for="item, index in creationKeywordResult" :key="index">{{ item }}</p>
            </div>
            <div class="digest_wrap">
              <p class="creation_list_label">핵심요약</p>
              <div class="digest origin">
                {{ creationDigestResult }}
              </div>
            </div>
          </template>
          <template v-else>
            <template v-if="keywordExportloading == false">
              <p class="btn" @click="create_digest_with_keyword()">업로드한 문서의 키워드와 핵심요약 불러오기</p>
            </template>
            <template v-else>
              <p class="confirm loading">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </p>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>